<template>
  <div id="DemoCrypto">
    <b-container>
      <b-card style="margin-bottom: 20px" header="Create Order">
        <b-row>
          <b-col>
            <b-form-select v-model="form.currency1" :options="option_fiat"></b-form-select>
          </b-col>
          <b-col>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-select v-model="form.currency2" :options="option_crypto"></b-form-select>
          </b-col>
        </b-row>
      </b-card>
      <b-card style="margin-bottom: 20px" header="Exchange Rate">
        <exchange :rate="rate"/>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import exchange from "@/components/crypto/exchange";

export default {
  name: "CryptoDemo",
  beforeMount() {
    this.getExchange()
  },
  components: {
    exchange
  },
  computed: {
    convertTable() {
      const fiat = this.rate.filter(value => value.is_fiat)
      const crypto = this.rate.filter(value => !value.is_fiat)
      const result = []
      for (const x of crypto) {
        const Model = {
          name: x.name,
          coin: x.coin
        }
        for (const y of fiat) {
          Model[y.coin] = (x.rate_btc / y.rate_btc).toLocaleString({maximumFractionDigits: 2})
        }
        result.push(Model)
      }
      return result
    },
    option_fiat(){
      const fiat = this.rate.filter(value => value.is_fiat)
      const result = []
      for(const x of fiat){
        result.push(x.coin)
      }
      return result
    },
    option_crypto(){
      const crypto = this.rate.filter(value => !value.is_fiat)
      const result = []
      for(const x of crypto){
        result.push(x.coin)
      }
      return result
    }
  },
  data() {
    return {
      rate: [],
      form: {
        amount: 0,
        currency1: '',
        currency2: ''
      }
    }
  },
  methods: {
    async getExchange() {
      try {
        const result = await this.axios.get('api/demo/crypto/rate')
        this.rate = result.data
      } catch (e) {
        this.$swal('Error api', '', 'error')
      }
    }
  }
}
</script>

<style scoped>
#card {
  margin-bottom: 20px;
}
</style>
