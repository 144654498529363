<template>
  <div>
    <b-table bordered striped hover :items="convertTable"></b-table>
  </div>
</template>

<script>
export default {
  name: "exchange",
  props: ['rate'],
  computed: {
    convertTable() {
      const fiat = this.rate.filter(value => value.is_fiat)
      const crypto = this.rate.filter(value => !value.is_fiat)
      const result = []
      for (const x of crypto) {
        const Model = {
          name: x.name,
          coin: x.coin
        }
        for (const y of fiat) {
          Model[y.coin] = (x.rate_btc / y.rate_btc).toLocaleString({maximumFractionDigits: 2})
        }
        result.push(Model)
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
